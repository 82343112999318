import React, { useEffect, useMemo, useRef, useState } from "react";
import style from "./boards-grid.module.css";
import { Board, BoardState } from "shared/datamodel/schemas/board";
import { BoardCard } from "./board-card";
import { useRouter } from "next/router";
import useStateValue from "frontend/state/value";
import AppHeader from "frontend/app-header/app-header";
import { createBoard } from "frontend/services/boardsService";
import useAuthentication from "frontend/hooks/use-authentication";
import Modal from "frontend/modal/modal";
import ErrorModal from "frontend/error/error-modal";
import MaxUsersPerBoardError from "frontend/error/max-users-per-board-error";
import StyledButton from "frontend/ui-components/styled-button";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import EditSeatsModal from "frontend/modals/edit-seats-modal";
import { useAccountReps } from "frontend/hooks/use-account-reps";
import { useSortedBoards } from "frontend/subscriptions";
import { useAtom, useAtomValue } from "jotai";
import { isBannerNotificationOnAtom, isBusyAtom } from "state-atoms/general-atoms";
import { BlankCanvasId, CanvasTemplate } from "shared/datamodel/schemas/canvas-template";
import TemplateSection from "./templates-section";
import { SideMenu } from "./side-menu/side-menu";
import Checkout from "frontend/billing/checkout";
import classNames from "classnames";
import NoCanvasesEmptystate from "frontend/boards-grid/no-canvases-empty-state";
import { InviteMode, userTipsAtom } from "state-atoms";
import { Team } from "shared/datamodel/schemas/team";
import { Project } from "shared/datamodel/schemas/project";
import CollaborationHeader from "frontend/modals/collaboration/collaboration-header";
import { PortalID } from "frontend/modal/usePortal";
import Skeleton from "react-loading-skeleton";
import { TipName } from "shared/datamodel/schemas";
import MoveCanvasModals from "frontend/modals/collaboration/move-canvas-modal";
import MoveCanvasToast from "frontend/ui-components/move-canvas-toast";
import UpgradeModalAfterDowngrade from "frontend/modals/upgrade/upgrade-after-downgrade";
import useFeatureValue from "frontend/hooks/use-features";
import useModalDeepLink from "frontend/hooks/use-modal-deep-link";
import ModalManager, { ModalAction, ModalType } from "frontend/modals/modal-manager";
import { RESET } from "jotai/utils";
import { InstanceType } from "shared/datamodel/schemas/ability";
import useAbility from "frontend/hooks/use-ability";
import RestrictedUserModal from "frontend/modals/restricted-user-modal";
import InviteModal from "frontend/modals/invite-modal";
import { useTeam } from "frontend/hooks/use-team";
import RestrictedUserNoticeModal from "frontend/modals/restricted-user-notice-modal";
import { markTipSeen } from "frontend/api";
import { getPathPrefix } from '../utils/getPathPrefix';
import { SearchSection } from "frontend/boards-grid/search-section/search-section";
import { navigateToBoard } from "frontend/boards-grid/boards-grid-utils";
import { useRecentSelected } from "frontend/boards-grid/search-section/hooks/use-recent-selected";
import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import { BatchedItemsGrid } from "./batched-items-grid";

export type GridError = {
  type: "limitReached";
  data: any;
};

export function BoardsGrid({ showGridError }: { showGridError?: GridError | null }) {
  enum AdminModalType {
    inviteModal = 0,
    EditSeatsModal = 1,
  }

  const [{ boards, user }, dispatch] = useStateValue();
  const [sortedBoards, setSortedBoards] = useState<[string, Board[]][]>([]);
  const [error, setError] = useState<any | null>(null);
  const { isReady } = useAuthentication(true);
  const [adminModalTypeToShow, setAdminModalTypeToShow] = useState<AdminModalType | null>(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showDowngradeNoticeModal, setShowDowngradeNoticeModal] = useState<boolean>(false);
  const [showSubscriptionCanceledNotice, setShowSubscriptionCanceledNotice] = useState<boolean>(false);
  const [showRestrictedNoticedModal, setShowRestrictedNoticeModal] = useState<boolean>(false);
  const [canvasToMove, setCanvasToMove] = useState<Board | null>(null);
  const { accountRepsInstance } = useAccountReps();
  const [isBusy, setIsBusy] = useAtom(isBusyAtom);
  const userTips = useAtomValue(userTipsAtom);
  const isBannerNotificationOn = useAtomValue(isBannerNotificationOnAtom);
  const allSortedBoards = useSortedBoards(accountRepsInstance, boards);
  const [canvasMovingMessage, setCanvasMovingMessage] = useState<JSX.Element | null>(null);
  const { onSelected } = useRecentSelected();

  const { myTeams } = useTeam();
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [inviteSource, setInviteSource] = useState<string>("board-grid-app-header");
  const { canCreateInstance, canPerformAnyAction } = useAbility();
  const instance = selectedProject ?? selectedTeam;
  const instanceType = selectedProject ? InstanceType.Project : InstanceType.Team;
  const shouldShoWRestrictedModal = user && instance && !canPerformAnyAction(user, instance, instanceType);

  const isSearchEnabeld = useFeatureFlag("search-board-feature");

  const maxAllowedCanvases_str = useFeatureValue(consts.FEATURE_NAMES.MAX_ALLOWED_CANVASES);
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";

  const { setModalDeepLink } = useModalDeepLink();
  const isMondayUser = user?.account?.id === "5";

  const eventSent = useRef(false);

  const router = useRouter();

  useEffect(() => {
    if (!allSortedBoards) {
      return;
    }
    const projectsBoards = allSortedBoards.filter(
      (board) => board.projectId && board.projectId === selectedProject?.id
    );
    const teamBoards = selectedTeam?.accountTeam
      ? null
      : allSortedBoards.filter((board) => board.teamId && board.teamId === selectedTeam?.id && !board.projectId);

    const myBoards = allSortedBoards.filter(
      (board) =>
        board.isOwner &&
        !board.projectId &&
        selectedTeam?.accountTeam &&
        (selectedTeam.id === board.teamId || board.teamId === null)
    );
    const allBoards = allSortedBoards.filter(
      (board) =>
        !board.isOwner &&
        !board.projectId &&
        selectedTeam?.accountTeam &&
        (selectedTeam.id === board.teamId || board.teamId === null) &&
        board.hasMemberAccess
    );

    if (!eventSent.current) {
      //avoid sending duplicate events, bah.
      tracking.trackEvent(consts.TRACKING_CATEGORY.PAGE_VIEWS, "boards_page_view", user?.account?.id, user?.id);
      eventSent.current = true;
    }

    let sections = null;
    if (selectedProject) {
      if (projectsBoards.length === 0) {
        sections = [];
      } else {
        sections = sections ?? ([] as [string, Board[]][]);
        sections.push([`Canvases in "${selectedProject.name}" project`, projectsBoards]);
      }
    }
    if (teamBoards && !selectedProject) {
      if (teamBoards.length === 0) {
        sections = [];
      } else {
        sections = sections ?? [];
        sections.push(["Canvases in this team", teamBoards]);
      }
    }
    if (myBoards.length > 0 && !selectedProject) {
      sections = sections ?? [];
      sections.push(["My canvases", myBoards]);
    }
    if (allBoards.length > 0 && !selectedProject) {
      sections = sections ?? [];
      sections.push(["All canvases", allBoards]);
    }

    setSortedBoards(sections ?? []);
  }, [allSortedBoards, selectedProject?.id, selectedProject?.name, selectedTeam?.id]);

  useEffect(() => {
    if (router && router.query.board_users_limit && router.query.maxAllowedCollaborators) {
      const boardName = decodeURIComponent(router.query.board_users_limit.toString());
      const maxAllowedCollaborators = decodeURIComponent(router.query.maxAllowedCollaborators.toString());
      router.query = {};
      setError(
        <MaxUsersPerBoardError
          boardName={boardName}
          onDismiss={() => {
            setError(null);
            router.replace("/");
          }}
          maxAllowedCollaborators={parseInt(maxAllowedCollaborators)}
        />
      );
    }
  }, [router]);

  useEffect(() => {
    if (showGridError) {
      switch (showGridError.type) {
        case "limitReached":
          setError(showUpgradeRequiredModal(showGridError.data));
          break;
        default:
          break;
      }
    }
  }, [showGridError]);

  useEffect(() => {
    if (user?.planInfo?.is_downgraded && user.isAdmin && !userTips.has(TipName.downgradeNotice)) {
      setShowDowngradeNoticeModal(true);
    }
    if (
      user?.planInfo?.is_downgraded &&
      user?.planInfo?.was_subscription_cancelled &&
      user.isAdmin &&
      !userTips.has(TipName.subscriptionCanceledNotice)
    ) {
      setShowSubscriptionCanceledNotice(true);
    }
    if (applyRestrictedLimitation && !userTips.has(TipName.restrictedUsersNotice) && shouldShoWRestrictedModal) {
      setShowRestrictedNoticeModal(true);
    }
  }, [userTips]);

  //Hide the move canvas message after some interval
  useEffect(() => {
    let timeOut: any;
    if (canvasMovingMessage) {
      timeOut = setTimeout(() => {
        setCanvasMovingMessage(null);
      }, 6000);
    }
    return () => timeOut && clearTimeout(timeOut);
  }, [canvasMovingMessage]);

  useEffect(() => {
    if (!user?.canInvite && adminModalTypeToShow === AdminModalType.inviteModal) {
      tracking.trackEvent(consts.TRACKING_CATEGORY.INVITE_ACTION, "no_permission_to_invite_modal", "invite-to-account");
    }
  }, [user?.canInvite, adminModalTypeToShow]);

  function onEditSeats() {
    setAdminModalTypeToShow(AdminModalType.EditSeatsModal);
  }

  function handleModalAction(action: ModalAction) {
    if (action) {
      switch (action.type) {
        case "chooseTemplate":
          onChooseTemplate(action.payload);
          break;
      }
    }
  }

  function showNoPermissionToCreateModal() {
    if (shouldShoWRestrictedModal) {
      return <RestrictedUserModal trackingEvent={"home"} onDismiss={() => setError(null)} />;
    }
    return (
      <Modal dimBackground={true} onClickBackground={() => setError(null)}>
        <div className={style.contactYourAdmin}>
          <CollaborationHeader
            title={`You are not an editor in this ${instanceType}`}
            subtitle={`You are viewer in this ${instanceType}, to have editing permissions and create more canvases, please request access from the ${instanceType} owner.`}
            onDismiss={() => setError(null)}
          />
          <StyledButton title={"Got it"} customStyle={{ width: "107px" }} onClick={() => setError(null)} />
        </div>
      </Modal>
    );
  }

  function showUpgradeRequiredModal(numBoards: number) {
    if (numBoards === 0) {
      return null;
    }
    tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.CANVAS_LIMIT_REACHED);
    return (
      <ErrorModal title="Having fun, are we?" onDismiss={() => setError(null)}>
        <span>
          You have reached the <b>{`${numBoards}`} canvas</b> limit for this free version. Upgrade to get unlimited
          canvases.
        </span>
        <StyledButton
          title="Upgrade"
          onClick={() => {
            tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
            tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", "upgrade-board-card");
            setShowUpgradeModal(true);
            //setIsBusy(true);
            setError(null);
          }}
        />
      </ErrorModal>
    );
  }

  async function createNewBoard(template: Omit<CanvasTemplate, "data"> & { data?: any }, projectId?: number) {
    setIsBusy(true);
    if (template.upgradeRequired) {
      setModalDeepLink({ modalType: ModalType.PreviewTemplate, id: template.id, name: template.name });
      setIsBusy(false);
      return;
    }

    const maxAllowedCanvases = parseInt(maxAllowedCanvases_str);

    if (boards.length >= maxAllowedCanvases) {
      setError(showUpgradeRequiredModal(maxAllowedCanvases));
      setIsBusy(false);
      return;
    }
    const instance = selectedProject ?? selectedTeam;
    const instanceType = selectedProject ? InstanceType.Project : InstanceType.Team;
    if (user && instance && !canCreateInstance(user, instance, instanceType)) {
      setError(showNoPermissionToCreateModal());
      setIsBusy(false);
      return;
    }
    if (!selectedTeam) {
      return;
    }
    try {
      const userName = user?.name.split(" ")[0];
      const name = template.id == BlankCanvasId ? (userName ? `${userName}'s Canvas` : "New Canvas") : template.name;
      const boardState = BoardState.templateNotSetUp;
      const board = await createBoard(
        name,
        boardState,
        template.id,
        projectId ?? selectedProject?.id ?? null,
        selectedTeam?.id,
        dispatch
      );
      if (board) {
        tracking.trackEvent(
          consts.TRACKING_CATEGORY.CANVAS_ACTION, // category
          "new_canvas", // action
          template.name, // string value
          board.accountId // property
        );

        window.sessionStorage.setItem(consts.SESSION_STORAGE_KEYS.FIRST_ACCESS, "true");
        if (template.data) {
          window.sessionStorage.setItem(
            consts.SESSION_STORAGE_KEYS.CREATED_TEMPLATE_DATA + board.documentId,
            JSON.stringify(template.data)
          );
        }
        board.createdFromTemplateId
          ? router.push(`/d/${board.documentId}`)
          : router.push(`/d/${board.documentId}#show=templateLibrary`);
      }
    } catch (e: any) {
      if (e.response.status === 406) {
        setError(showUpgradeRequiredModal(e.response.data.maxBoardsAllowed));
      }
    } finally {
      setIsBusy(false);
    }
  }

  function handleNoPermissionToCreate() {
    setError(showNoPermissionToCreateModal());
    setIsBusy(false);
    return;
  }

  function renderCard(board: Board, index: number) {
    return (
      <BoardCard
        onClick={() => {
          onSelected(board.documentId);
          navigateToBoard(board);
        }}
        board={board}
        key={board.id}
        showDynamicPreview={false}
        board_index={index}
        onDuplicateError={(number) => setError(showUpgradeRequiredModal(number))}
        setCanvasToMove={setCanvasToMove}
      />
    );
  }

  function renderBoardsEmptyState(text: string) {
    return <NoCanvasesEmptystate subtitle={text} createNewBoard={createNewBoard} />;
  }

  function renderBoards() {
    if (!selectedTeam) {
      return (
        <>
          <Skeleton count={1} className={style.titleLine} style={{ width: "50%", height: "40px" }} />
          <Skeleton count={10} className={style.skeletonCard} containerClassName={style.skeletonCardContainer} />
        </>
      );
    } else if (!selectedProject && sortedBoards?.length == 0) {
      return renderBoardsEmptyState("No canvases yet!\n Create a new canvas for this team");
    } else if (selectedProject) {
      if (sortedBoards.length > 0) {
        const firstSection = sortedBoards[0];
        const projectsBoards = firstSection[1];
        return (
          <div key={selectedProject.name} className={style.section}>
            <span className={style.titleLine}>{firstSection[0]}</span>
            <div className={style.listContainer}>{projectsBoards.map(renderCard)}</div>
          </div>
        );
      } else {
        return renderBoardsEmptyState("No canvases in this project yet!\n Create a new canvas for this project");
      }
    }
    return sortedBoards.map(([name, boards]) => (
      <div key={name} className={style.section}>
        <span className={style.titleLine}>{name}</span>
        <BatchedItemsGrid items={boards} gridContainerClass={style.boardsGrid} cellRender={renderCard} batchSize={50} />
      </div>
    ));
  }

  function onChooseTemplate({ template }: { template: CanvasTemplate }) {
    setModalDeepLink({ modalType: RESET });
    tracking.trackEvent(consts.TRACKING_CATEGORY.ADMIN_ACTION, "canvas_template_selected_main_screen", template.id);
    createNewBoard(template);
  }

  function onInviteClick(title?: string) {
    const underScoredTitle = title ? title.toLowerCase().replace(/ /g, "_") + "_" : "";
    title && setInviteSource(underScoredTitle);
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.ADMIN_ACTION,
      `${underScoredTitle}invite_button_clicked`,
      "invite_type",
      "account"
    );
    if (shouldShoWRestrictedModal) {
      setError(showNoPermissionToCreateModal());
    } else {
      setAdminModalTypeToShow(AdminModalType.inviteModal);
    }
  }

  return (
    <div
      id={"boards-container"}
      className={classNames(style.container, {
        [style.containerWithBanner]: isBannerNotificationOn,
        [style.containerMonday]: isSearchEnabeld,
      })}
    >
      <AppHeader
        isBoardHeader={false}
        showDowngradedNotification={user?.planInfo?.is_downgraded}
        onInviteClick={onInviteClick}
        onRestrictedClick={() => setError(showNoPermissionToCreateModal())}
      >
        <StyledButton
          title="Invite People"
          icon={getPathPrefix("/images/plus-icon.svg")}
          color="#1973FF"
          onClick={() => onInviteClick()}
          customStyle={{ letterSpacing: "0.124px" }}
        />
      </AppHeader>
      {adminModalTypeToShow !== null && selectedTeam && (
        <Modal dimBackground={true}>
          {adminModalTypeToShow === AdminModalType.EditSeatsModal ? (
            <EditSeatsModal onDismiss={() => setAdminModalTypeToShow(null)} />
          ) : (
            <InviteModal
              team={selectedTeam}
              onDismiss={() => setAdminModalTypeToShow(null)}
              onEditSeats={onEditSeats}
              inviteMode={InviteMode.Edit}
              source={inviteSource}
            />
          )}
        </Modal>
      )}
      <div
        className={style.contentBackground}
        style={{
          transform: "none",
        }}
      />
      <div className={classNames(style.left, { [style.leftWithBanner]: isBannerNotificationOn && isMondayUser })}>
        <SideMenu
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          setShowTemplatesModal={(show) =>
            show
              ? setModalDeepLink({ modalType: ModalType.TemplateLibrary })
              : setModalDeepLink({ modalType: ModalType.None })
          }
          createNewBoard={createNewBoard}
          handleNoPermissionToCreate={handleNoPermissionToCreate}
          onEditSeats={onEditSeats}
        />
      </div>
      {isSearchEnabeld && (
        <div
          className={style.searchTemplates}
          style={{
            pointerEvents: "auto",
          }}
        >
          <SearchSection />
        </div>
      )}
      <div
        className={style.templates}
        style={{
          transform: "none",
          pointerEvents: isBusy ? "none" : "auto",
        }}
      >
        {isReady && (
          <TemplateSection
            onCreateBoard={createNewBoard}
            onPreviewClicked={(template) => {
              setModalDeepLink({ modalType: ModalType.PreviewTemplate, id: template.id, name: template.name });
            }}
            onShowUpgradeModal={() => {
              setModalDeepLink({ modalType: RESET });
              setShowUpgradeModal(true);
            }}
            setShowTemplatesModal={(show) =>
              show
                ? setModalDeepLink({ modalType: ModalType.TemplateLibrary })
                : setModalDeepLink({ modalType: ModalType.None })
            }
          />
        )}
      </div>
      <div
        className={style.contentArea}
        style={{
          transform: "none",
          pointerEvents: isBusy ? "none" : "auto",
        }}
      >
        {isReady && renderBoards()}
      </div>
      <div className={style.footer}>
        <div className={style.logo}>
          <span>By</span>
          <img src={getPathPrefix("/images/monday-logo.svg")} />
        </div>
        <a href="https://monday.com/l/legal/tos/">Terms of use</a>
        <a href="https://monday.com/l/privacy/privacy-policy/">Privacy policy</a>
      </div>
      <ModalManager
        setIsLoading={setIsBusy}
        handleModalAction={handleModalAction}
        onShowUpgradeModal={() => {
          setModalDeepLink({ modalType: RESET });
          setShowUpgradeModal(true);
        }}
      />
      {error && <Modal dimBackground={true}>{error}</Modal>}
      {showUpgradeModal && (
        <Checkout
          user={user!}
          onDismiss={() => {
            setShowUpgradeModal(false);
          }}
        />
      )}
      {canvasToMove && (
        <Modal dimBackground={true}>
          <MoveCanvasModals
            board={canvasToMove}
            onDismiss={() => setCanvasToMove(null)}
            setCanvasMovingMessage={setCanvasMovingMessage}
            currentProject={selectedProject}
          />
        </Modal>
      )}
      {canvasMovingMessage && (
        <MoveCanvasToast content={canvasMovingMessage} onClose={() => setCanvasMovingMessage(null)} />
      )}
      {showDowngradeNoticeModal && (
        <UpgradeModalAfterDowngrade
          showUpgradeModalAfterDowngrade={setShowDowngradeNoticeModal}
          setShowUpgradeModal={setShowUpgradeModal}
          type="downgrade"
        />
      )}
      {showSubscriptionCanceledNotice && (
        <UpgradeModalAfterDowngrade
          showUpgradeModalAfterDowngrade={setShowSubscriptionCanceledNotice}
          setShowUpgradeModal={setShowUpgradeModal}
          type="subscriptionCanceled"
        />
      )}
      {showRestrictedNoticedModal && (
        <RestrictedUserNoticeModal
          trackingEvent={"home"}
          onDismiss={() => {
            markTipSeen(TipName.restrictedUsersNotice);
            setShowRestrictedNoticeModal(false);
          }}
        />
      )}
      <div id={PortalID.Floaters} />
      <div id={PortalID.Tooltips} />
    </div>
  );
}
