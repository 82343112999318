import React, { ReactNode, useRef, useState } from "react";
import { getYear, startOfDay, subDays, endOfToday, subMonths, startOfYear, format, isSameMonth } from "date-fns";
import { ChevronRight } from "frontend/icons/chevron";
import { DatePickerRangeInput } from "frontend/ui-components/date-picker-range/date-picker-range-input";
import commonSyles from "../search-common.module.css";
import { useSearchContext } from "frontend/boards-grid/search-section/context/search-context";
import { SearchFilterDate } from "frontend/boards-grid/search-section/types";
import styles from "./dates-filter.module.css";

const CUSTOM_DATE = "Custom date";

const createOptions = (): { key: string; label: string; component?: ReactNode; startDate?: Date; endDate?: Date }[] => {
  return [
    { key: "today", label: "Today", startDate: startOfDay(new Date()), endDate: endOfToday() },
    {
      key: "last-7-days",
      label: "Last 7 days",
      startDate: subDays(new Date(), 7),
      endDate: endOfToday(),
    },
    {
      key: "last-30-days",
      label: "Last 30 days",
      startDate: subDays(new Date(), 30),
      endDate: endOfToday(),
    },
    {
      key: "last-6-months",
      label: "Last 6 months",
      startDate: subMonths(new Date(), 6),
      endDate: endOfToday(),
    },
    {
      key: "this-year",
      label: `This year (${getYear(new Date())})`,
      startDate: startOfYear(new Date()),
      endDate: endOfToday(),
    },
    {
      key: "custom",
      label: CUSTOM_DATE,
      component: (
        <div className={styles.customDateOption}>
          {CUSTOM_DATE} <ChevronRight />
        </div>
      ),
    },
  ];
};

const formatCustomDateLabel = (start: Date | undefined, end: Date | undefined) => {
  let label = `${start ? format(start, "MMM dd") : ""}`;
  if (start && end && isSameMonth(start, end)) {
    label += format(end, "-dd");
  } else {
    label += `${end ? format(end, "-MMM dd") : ""}`;
  }
  return label;
};

type DatesFilterProps = {
  onDateSelected: (onDateSelected: { startDate?: Date; endDate?: Date; label: ReactNode }) => void;
};

export const DatesFilter = ({ onDateSelected }: DatesFilterProps) => {
  const { filters } = useSearchContext();
  const [selected, setSelected] = useState("");
  const OPTIONS = useRef(createOptions());
  const dateFilter = filters.find((f) => f.type === "date") as SearchFilterDate;

  return (
    <div className={styles.wrapper}>
      <div>
        {OPTIONS.current.map((option) => {
          return (
            <div
              className={commonSyles.option}
              key={option.key}
              data-isselected={option.label === selected}
              onClick={() => {
                setSelected(option.label);
                if (option.label !== CUSTOM_DATE) {
                  onDateSelected(option);
                }
              }}
            >
              {option?.component ?? option.label}
            </div>
          );
        })}
      </div>

      {selected === CUSTOM_DATE && (
        <div className={styles.customDate}>
          <div className={styles.line}></div>
          <DatePickerRangeInput
            fromDate={dateFilter?.value?.startDate ? new Date(dateFilter?.value?.startDate) : new Date()}
            toDate={dateFilter?.value?.endDate ? new Date(dateFilter?.value?.endDate) : undefined}
            onSelected={(start, end) => {
              onDateSelected({
                label: formatCustomDateLabel(start, end),
                startDate: start,
                endDate: end ?? endOfToday(),
              });
            }}
            theme="light"
          />
        </div>
      )}
    </div>
  );
};
