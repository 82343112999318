import useCanvasTemplates from "frontend/hooks/use-canvas-templates";
import { useAtomValue } from "jotai";
import { BlankCanvasTemplate, CanvasTemplateMinimal, TemplatePermission } from "shared/datamodel/schemas";
import { userAtom } from "state-atoms";
import style from "./templates-section.module.css";
import Skeleton from "react-loading-skeleton";
import { ShowAllTemplatesCard } from "./show-all-templates";
import { ResponsiveTemplateCard } from "./responsive-template-card";
import { useWindowSize } from "react-use";

//the width of the home page elements
const SIDE_MENU = 226;
const TEMPLATE_SECTION_SIDE_MARGIN = 37 * 2;
const TEMPLATE_SECTION_SIDE_PADDING = 22 * 2;
const SINGLE_TEMPLATE = 150;

export default function TemplateSection({
  onCreateBoard,
  onPreviewClicked,
  onShowUpgradeModal,
  setShowTemplatesModal,
}: {
  onCreateBoard: (template: CanvasTemplateMinimal) => void;
  onPreviewClicked: (template: CanvasTemplateMinimal) => void;
  onShowUpgradeModal: () => void;
  setShowTemplatesModal: (show: boolean) => void;
}) {
  const user = useAtomValue(userAtom);
  const { templates } = useCanvasTemplates();
  const windowSize = useWindowSize();

  function getExploreTemplates(templates: CanvasTemplateMinimal[] | null | undefined) {
    //calculate the space left for the template presented between 'new canvas' and 'all templates'
    const { width } = windowSize;
    const spaceForTemplates = width
      - SIDE_MENU
      - TEMPLATE_SECTION_SIDE_MARGIN
      - TEMPLATE_SECTION_SIDE_PADDING
      - SINGLE_TEMPLATE - SINGLE_TEMPLATE; // 'new canvas' and 'all templates'
    const lastIndex = Math.floor(spaceForTemplates / SINGLE_TEMPLATE) > 0 ? Math.floor(spaceForTemplates / SINGLE_TEMPLATE) : 0;

    if (!templates) {
      return renderTemplatesSkeleton();
    }

    const exploreTemplateNames = [
      "Org Chart",
      "Flowchart",
      "Role & Department Org Chart",
      "Meeting Agenda",
      "Flowing Mind Map",
      "Career progression paths",
      "Brainstorm",
      "Timeline",
      "Fishbone Diagram",
      "Scalability & Growth",
      "Venn Diagram",
    ];

    const templateModels = templates.filter((template) =>
      exploreTemplateNames.includes(template.name) && template.permission === TemplatePermission.Public
    );

    templateModels.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      return exploreTemplateNames.indexOf(nameA) - exploreTemplateNames.indexOf(nameB);
    });

    return (
      <>
        <ResponsiveTemplateCard
          template={BlankCanvasTemplate}
          previewAvailable={false}
          onClick={onCreateBoard}
          onPreview={() => onPreviewClicked(BlankCanvasTemplate)}
        />
        {templateModels.slice(0, lastIndex).map((template) => (
          <ResponsiveTemplateCard
            key={template.id}
            template={template}
            previewAvailable={template.alias !== "blank"}
            onClick={onCreateBoard}
            onPreview={() => onPreviewClicked(template)}
          />
        ))}
        <ShowAllTemplatesCard setShowTemplatesModal={setShowTemplatesModal} />
      </>
    );
  }

  function renderTemplatesSkeleton() {
    return (
      <Skeleton
        count={Math.floor((window.innerWidth - 224) / 133) - 1}
        className={style.skeleton}
        containerClassName={style.skeletonContainer}
      />
    );
  }

  if (!user?.planInfo) {
    return null;
  }

  return (
    <>
      <div className={style.section}>
        <div>
          <p className={style.titleLine}>Create a new canvas</p>
          <p className={style.subtitleLine}>Explore our recommended templates</p>
        </div>
        <div className={style.grid}>{getExploreTemplates(templates)}</div>
      </div>
    </>
  );
}
